import andino from '../img/places/andino.png'
import zipaquira from '../img/places/zipaquira.png'
import leyva from '../img/places/villadeleyva.png'
import candelaria from '../img/places/candelaria.png'
import chorro from '../img/places/chorro.png'
import monserrate from '../img/places/monserrate.png'
import catedral from '../img/places/catedral_de_sal.png'
import bolivar from '../img/places/simon_bolivar_plaza.png'
import parque93 from '../img/places/parque93.png'
import titan from '../img/places/titan.png'
import nuestrobogota from '../img/places/nuestro_bogota.png'
import centromayor from '../img/places/centro_mayor.png'
import santafe from '../img/places/santa_fe.png'
import oro from '../img/places/museo_oro.png'
import duque from '../img/places/jaime_duque.png'
import nacional from '../img/places/museo_nacional.png'
import calera from '../img/places/calera.png'
import campin from '../img/places/el_campin.png'
import simon from '../img/places/simon_bolivar.png'
import planetario from '../img/places/planetario.png'
import empresarial from '../img/places/centro_empresarial.png'
import medellin from '../img/places/medellin.png'
import cafetero from '../img/places/ejecafetero.png'
import guatavita from '../img/places/guatavita.png'

const data = [
  [ /* populares */
    {
      id: 90,
      img: medellin,
      title: 'Medellín',
      place: 'Antioquia',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 91,
      img: cafetero,
      title: 'Eje Cafetero',
      place: 'Caldas, Risaralda, Quindío',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 92,
      img: guatavita,
      title: 'Laguna de Guatavita',
      place: 'Cundinamarca',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 1,
      img: zipaquira,
      title: 'Zipaquirá',
      place: 'Zipaquirá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 2,
      img: leyva,
      title: 'Villa de Leyva',
      place: 'Boyacá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 3,
      img: candelaria,
      title: 'La Candelaria',
      place: 'Centro de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 4,
      img: chorro,
      title: 'Chorro de Quevedo',
      place: 'Centro de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 5,
      img: monserrate,
      title: 'Monserrate',
      place: 'Centro de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 6,
      img: catedral,
      title: 'Catedral de Sal',
      place: 'Zipaquirá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 7,
      img: bolivar,
      title: 'Plaza de Bolívar',
      place: 'Centro de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 8,
      img: parque93,
      title: 'Parque de la 93',
      place: 'Norte de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
  ],
  [ /* centros comerciales */
    {
      id: 10,
      img: titan,
      title: 'Titán Plaza',
      place: 'Occidente de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 11,
      img: andino,
      title: 'Andino',
      place: 'Norte de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 12,
      img: nuestrobogota,
      title: 'Nuestro Bogotá',
      place: 'Occidente de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 13,
      img: centromayor,
      title: 'Centro Mayor',
      place: 'Sur de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 14,
      img: santafe,
      title: 'Santa Fé',
      place: 'Norte de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
  ],
  [ /* turisteando */
    {
      id: 15,
      img: oro,
      title: 'Museo del Oro',
      place: 'Centro de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 16,
      img: duque,
      title: 'Parque Jaime Duque',
      place: 'Tocancipá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 17,
      img: nacional,
      title: 'Museo Nacional',
      place: 'Centro de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 18,
      img: campin,
      title: 'El Campín',
      place: 'Oriente de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 19,
      img: calera,
      title: 'Mirador La Calera',
      place: 'Vía La Calera',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 20,
      img: simon,
      title: 'Parque Simón Bolivar',
      place: 'Oriente de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 21,
      img: planetario,
      title: 'Planetario',
      place: 'Centro de Bogotá',
      schedule: '2h 30min',
      price: 120000
    },
    {
      id: 22,
      img: empresarial,
      title: 'Centro Empresarial',
      place: 'Santa Bárbara',
      schedule: '2h 30min',
      price: 120000
    },
  
  ],
]

export default data