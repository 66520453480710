import React from 'react'
import Layout from '../layout/Layout'
import Hero from './Hero'
import CallToAction from './CallToAction'
import WhyUs from './WhyUs'
import Help from './Help'
import Tabs from './Tabs'
import Van from './Van'
import Form from './Form'
import { useSelector } from 'react-redux'

const Home = () => {

  const {spanish} = useSelector(s => s.languaje)


  return (
    <>
      <Layout spanish={spanish}>
        <Hero spanish={spanish}/>
        <Form spanish={spanish}/>
        <Van spanish={spanish}/>
        <Tabs spanish={spanish}/>
        <Help spanish={spanish}/>
        <WhyUs spanish={spanish}/>
        <CallToAction spanish={spanish}/>
      </ Layout>
    </>
  )
}

export default Home