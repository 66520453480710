import React from 'react'
import van from '../img/brand/van.png'

const Van = ({spanish}) => {

  const listSpanish = [
    {id:1, text:"Seguridad en cada kilómetro"},
    {id:2, text:"Capacidad para 7 personas"},
    {id:3, text:"Comodidad y espacio"},
    {id:4, text:"Versatilidad en el servicio"},
    {id:5, text:"Atención personalizada"},
    {id:6, text:"Flexibilidad y disponibilidad"},
  ]

  const listEnglish = [
    {id:1, text:"Safety in every kilometer"},
    {id:2, text:"Capacity for 7 persons"},
    {id:3, text:"Comfort and space"},
    {id:4, text:"Versatility in service"},
    {id:5, text:"Personalized attention"},
    {id:6, text:"Flexibility and availability"},
  ]

  return (
    <section className='py-3'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-11 align-self-center">
            <img src={van} alt="" className='img-fluid'/>
          </div>
          <div className="col-md-5 col-11 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-10 mt-4 mt-md-0">
                {
                  spanish ? (
                    <>
                      {
                        listSpanish.map(el => 
                          <div className="itemList" key={el.id}>
                            {el.text}
                          </div>
                        )
                      }
                    </>
                  ):
                  (
                    <>
                      {
                        listEnglish.map(el => 
                          <div className="itemList" key={el.id}>
                            {el.text}
                          </div>
                        )
                      }
                    
                    </>
                  )
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Van