import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay} from 'swiper/modules'
import banner1 from '../img/brand/banner1.png'
import banner2 from '../img/brand/banner2.png'
import banner3 from '../img/brand/banner3.png'

const Hero = ({spanish}) => {
  return (
    <section className="pt-2 pt-md-4 pb-5" id='home'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5 col-11 align-self-center">
            {
              spanish ? (
                <>
                  <h1 className='display-4 text-center text-md-start mb-4 mb-md-3'>Explora  <br className="d-none d-md-block"/> Colombia <br className="d-none d-md-block"/>con seguridad</h1>
                  <h5 className="font-light text-center text-md-start mb-3 mb-md-0">Separa tu viaje y disfruta los mejores momentos de tu vida.</h5>   
                </>
              ):
              (
                <>
                  <h1 className='display-4 text-center text-md-start mb-4 mb-md-3'>Let's explore  <br className="d-none d-md-block"/> Colombia <br className="d-none d-md-block"/>safely</h1>
                  <h5 className="font-light text-center text-md-start mb-3 mb-md-0">Set aside your trip and enjoy the best moments of your life.</h5>
                </>
              )
            }
          </div>
          <div className="col-md-7 align-self-center order-first order-md-last px-0 px-md-3">
          <Swiper
              spaceBetween={10}
              slidesPerView={1}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              <SwiperSlide>
                <img src={banner1} alt="" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={banner2} alt="" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={banner3} alt="" className="img-fluid" />
              </SwiperSlide>
              
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero