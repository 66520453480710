import React from 'react'
import Card from './Card'
import data from './dataCards'


const Tabs = ({spanish}) => {




  return (
    <section className="py-5">
      <div className="container py-2 py-md-5">
        <div className="text-center">
          <h2 id="trip">{spanish ?'¿Ya planeaste tu próximo viaje?':'Have you already planned your next trip?'} </h2>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="d-flex justify-content-center">
                <ul className="nav nav-tabs my-5" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">{spanish?'Más populares': 'Most popular'} </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">{spanish?'Centros comerciales': 'Shopping mall'}</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">{spanish?'Turisteando': 'Touring'}</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
              <div className="row justify-content-center">
                <div className="col-md-12 col-11">
                  <div className="row">
                    {
                      data[0].map(el =>
                        <Card
                          key={el.id}
                          id={el.id}
                          img={el.img}
                          title={el.title}
                          place={el.place}
                          schedule={el.schedule}
                          price={el.price}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
            <div className="row justify-content-center">
                <div className="col-md-12 col-11">
                  <div className="row">
                    {
                      data[1].map(el =>
                        <Card
                          key={el.id}
                          id={el.id}
                          img={el.img}
                          title={el.title}
                          place={el.place}
                          schedule={el.schedule}
                          price={el.price}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
              <div className="row justify-content-center">
                <div className="col-md-12 col-11">
                  <div className="row">
                    {
                      data[2].map(el =>
                        <Card
                          key={el.id}
                          id={el.id}
                          img={el.img}
                          title={el.title}
                          place={el.place}
                          schedule={el.schedule}
                          price={el.price}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tabs