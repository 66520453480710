import React from 'react'
import woman from '../img/brand/woman.png'

const Help = ({spanish}) => {
  return (
    <section className='bg-white py-5'>
      <div className="container py-2 py-md-5">
        <div className="row justify-content-center">
          <div className="col-md-12 col-11">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <h3 className='mb-3'>
                  {
                    spanish ?
                      '¿Necesitas una solicitud especial para tu viaje?'
                      :
                      'Do you need a special application for your trip?'
                  }
                  </h3>
                <div className="row">
                  <div className="col-md-8">
                    <p className='py-3'>
                      {
                        spanish  ? 
                          'Siempre estamos atentos a escucharte para dar solución a los requerimientos y recomendaciones que necesites para el confort de tu viaje'
                          :
                          'We are always attentive to listen to you to provide solutions to the requirements and recommendations you need for the comfort of your trip.'
                      }
                    </p>
                  </div>
                </div>
                <a href="https://api.whatsapp.com/send?phone=573105671105&text=Hola,%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20un%20servicio%20de%20transporte%20desde..." className='btnWhatsApp' target='_blank' rel='noreferrer noopener'>{spanish?'Escríbenos': 'Let`s chat'}</a>
              </div>
              <div className="col-md-6 align-self-center">
                <img src={woman} alt=""  className='img-fluid'/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Help