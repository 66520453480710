import React from 'react';
/* import formatPrice from '../tools/format'; */

const Card = ({ img, title, place, id }) => {

  const handleSelect = () => {
    const numeroTelefono = "573105671105"; // Número de WhatsApp al que se enviará el mensaje
    const mensaje = `Hola, quisiera conocer más sobre un servicio para ir a ${title} en ${place}.`;

    const urlWhatsApp = `https://wa.me/${numeroTelefono}?text=${encodeURIComponent(mensaje)}`;
    window.open(urlWhatsApp, '_blank');
  };

  return (
    <div className='col-md-4 mb-3' key={id}>
      <button
        className="cardTrip"
        onClick={handleSelect}
        type='button'
      >
        <img src={img} alt="" className='img-fluid'/>
        <div className="row">
          <div className="col-12 align-self-center">
            <h5>{title}</h5>
            <small className='d-block'>{place}</small>
          </div>
          {/* <div className="col-6 align-self-center">
            <div className="schedule">{schedule}</div>
            <p className="m-0">
              <span>desde</span>
              <strong>{formatPrice(price)}</strong>
            </p>
          </div> */}
        </div>
      </button>
    </div>
  );
};

export default Card;
