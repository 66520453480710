import React from 'react'
import logo from '../img/vector/logo_tu_viaje_vip.svg'
import { Link } from 'react-router-dom'
import gotosection from './gotosection'
import { useDispatch } from 'react-redux'
import { setLanguaje } from '../redux/languaje'

const Header = ({spanish}) => {

  const dispatch = useDispatch()

  const handleLanguage = (lang)=>{
    lang === 'en' && dispatch(setLanguaje(false))
    lang === 'es' && dispatch(setLanguaje(true))
  }

  return (
    <>
      <header className="headerLog d-none d-md-block">
        <div className="container">
          <div className="row">
            <div className="col-md-4 align-self-center">
              <img src={logo} alt="" className='img-fluid logo'/>
            </div>
            <div className="col-md-8 align-self-center">
              <nav>
                <Link to="/" className='home' onClick={()=> gotosection('home')}/>
                <Link to="/"  className='link' onClick={()=> gotosection('trip')}>{spanish ? 'Destinos': 'Destination'}</Link>
                <Link to="/"  className='link' onClick={()=> gotosection('about')}>{spanish ? 'Nosotros': 'About Us'}</Link>
                <a
                  href="https://googe.com"
                  target='_blank'
                  rel='noopener noreferrer'
                  className='btnGold'
                > {spanish ? '¡Llámanos ahora!': 'Call Us Now!'}</a>
                <div className="languages">
                  <button
                    type='button'
                    onClick={()=> handleLanguage('en')}
                    className={`en ${spanish? '': 'active'}`} 
                  />
                  <button
                    type='button'
                    onClick={()=> handleLanguage('es')}
                    className={`es ${spanish? 'active': ''}`} 
                  />
                </div>
              </nav>
              
            </div>
          </div>
        </div>
      </header>

      <div className="floatNav d-none d-md-block">
        {/* <a href="#" target='_blank' className='instagram'/> */}
        <a href="mailto:ttes@tuviajevip.com" className='mail'></a>
        <a href="https://api.whatsapp.com/send?phone=573105671105&text=Hola," target='_blank' className='wsp'/>
      </div>
      <header className='headerLog d-block d-md-none'>
        <div className="container">
          <div className="row">
            <div className="col-6 align-self-center">
              <img src={logo} alt="" className='img-fluid logo'/>
            </div>
            <div className="col-6 align-self-center">
              <nav>
                <div className="languages">
                  <button
                    type='button'
                    onClick={()=> handleLanguage('en')}
                    className={`en ${spanish? '': 'active'}`} 
                  />
                  <button
                    type='button'
                    onClick={()=> handleLanguage('es')}
                    className={`es ${spanish? 'active': ''}`} 
                  />
                </div>                
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header