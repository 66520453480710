import React from 'react'
import logo from '../img/vector/logo_tu_viaje_vip.svg'
import gotosection from './gotosection'



const Footer = ({spanish}) => {

  const currentYear = new Date()

  return (
    <>
      <footer className='footerLog pt-4'>
        <div className="text-center">
          <img src={logo} alt="" className='img-fluid logo'/>
          <nav>
            <button onClick={()=> gotosection('home')}>{spanish?'Inicio': 'Home'}</button>
            <button onClick={()=> gotosection('trip')}>{spanish ? 'Destinos': 'Destination'}</button>
            <button onClick={()=> gotosection('about')}>{spanish ? '¿Por qué elegirnos?': 'Why Us?'}</button>
          </nav>
        </div>
        <div className="floatNav d-block d-md-none socialmedia">
          <a href="mailto:ttes@tuviajevip.com" className='mail'></a>
          <a href="https://api.whatsapp.com/send?phone=573105671105&text=Hola," target='_blank' className='wsp'/>
        </div>
        <div className="bg-white py-3 px-5 text-center">
          {
            spanish ? (
              <>
                <small>Copyright TUVIAJEVIP {currentYear.getFullYear()} © todos los derechos reservados</small>
                <p className="m-0 cozmo">Desarrollado por <a href="http://cozmo.com.co/" target="_blank" rel='noopener noreferrer'>COZMO WEB</a></p>   
              </>
            ):
            (
              <>
                <small>Copyright TUVIAJEVIP {currentYear.getFullYear()} © all rights reserved</small>
                <p className="m-0 cozmo">Made with <a href="http://cozmo.com.co/" target="_blank" rel='noopener noreferrer'>COZMO WEB</a></p>
              </>
            )
          }
        </div>

      </footer>

      <div className="footerMobile">
        <div>
          <button className='home' onClick={()=> gotosection('home')}/>
          <button className='trip' onClick={()=> gotosection('trip')}/>
          <button className='about' onClick={()=> gotosection('about')}/>
          <a href="https://api.whatsapp.com/send?phone=573105671105&text=Hola," target='_blank' className='wsp'/>
        </div>
      </div>
    </>
  )
}

export default Footer