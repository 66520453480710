import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import people from '../img/brand/why_us.png'

import { Autoplay, Navigation, Pagination } from 'swiper/modules'


const WhyUs = ({spanish}) => {

  const infoSpanish = [
    {
      id:1,
      title: 'Versatilidad en el Servicio',
      text: 'No importa cuál sea tu destino o necesidad de transporte, estamos listos para ofrecerte una solución adaptada a ti.',
    },
    {
      id:2,
      title: 'Puntualidad y Confiabilidad',
      text: 'Entendemos la importancia del tiempo y nos comprometemos a estar siempre a tiempo, garantizando que llegues a tu destino sin contratiempos.',
    },
    {
      id:3,
      title: 'Seguridad en Cada Kilómetro',
      text: 'Nuestro vehículo está equipado con la más alta tecnología de seguridad, y nuestros conductores están capacitados para brindarte un viaje tranquilo y seguro.',
    },
    {
      id:4,
      title: 'Comodidad y Espacio',
      text: 'Viaja con comodidad en un vehículo amplio y bien mantenido, ideal para familias, grupos de amigos, o cualquier pasajero que valore un viaje confortable.',
    },
    {
      id:5,
      title: 'Atención Personalizada',
      text: 'Nos enorgullecemos de ofrecer un servicio al cliente excepcional, adaptado a tus necesidades y siempre con una sonrisa.',
    },
    {
      id:6,
      title: 'Transporte para Eventos Especiales',
      text: '¿Tienes un evento o celebración? Deja el transporte en nuestras manos y disfruta del momento sin preocupaciones.',
    },
  ]

  const infoEnglish = [
    {
      id:1,
      title: 'Versatility in Service',
      text: 'No matter what your destination or transportation needs are, we are ready to offer you a customized solution.',
    },
    {
      id:2,
      title: 'Punctuality and Reliability',
      text: 'We understand the importance of time and are committed to always being on time, ensuring that you arrive at your destination without mishaps.',
    },
    {
      id:3,
      title: 'Safety Every Kilometer',
      text: 'Our vehicle is equipped with the latest safety technology, and our drivers are trained to provide you with a safe and smooth ride.',
    },
    {
      id:4,
      title: 'Comfort and Space',
      text: 'Travel in comfort in a spacious and well-maintained vehicle, ideal for families, groups of friends, or any passenger who values a comfortable ride.',
    },
    {
      id:5,
      title: 'Personalized Attention',
      text: 'We pride ourselves on providing exceptional customer service, tailored to your needs and always with a smile.',
    },
    {
      id:6,
      title: 'Transportation for Special Events',
      text: 'Do you have an event or celebration? Leave the transportation in our hands and enjoy the moment without worries.',
    },
  ]

  return (
    <section className='py-5'>
      <div className="container  py-2 py-md-5">
        <div className="row justify-content-center">
          <div className="col-md-12 col-11">
            <div className="row">  
              <div className="col-12 text-center mb-4 mb-md-5">
                <h2 className='display-5' id='about'>{spanish?'¿Por qué elegirnos?':'Why choose us?'}</h2>
              </div>
              <div className="col-md-6 align-self-center position-relative">
                <h3 className='mb-4'>{spanish?'¿Quiénes somos?':'Who are we?'}</h3>
                <p>
                  {
                    spanish?
                      'Somos un servicio de transporte integral en Bogotá, enfocado en ofrecerte comodidad, seguridad y puntualidad en cada viaje. Ya sea que necesites un traslado desde el aeropuerto, la terminal de transportes, o un transporte especializado para fines turísticos, de negocios o eventos.'
                      :
                      'We are an integral transportation service in Bogota, focused on offering you comfort, safety and punctuality in every trip. Whether you need a transfer from the airport, the transportation terminal, or a specialized transportation for tourism, business or events.'
                  }
                </p>
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                    el: '.swiper-pagination',
                  }}
                  modules={[Autoplay, Navigation, Pagination]}

                >
                  {
                    spanish ? 
                    (
                      <>
                        {
                          infoSpanish.map(el =>
                            <SwiperSlide key={el.id}>
                              <div className="cardComments">
                                <h5>{el.title}</h5>
                                <p className="m-0">{el.text}</p>
                              </div>
                            </SwiperSlide>

                          )
                        }
                      </>
                    ):
                    (
                      <>
                        {
                          infoEnglish.map(el =>
                            <SwiperSlide key={el.id}>
                              <div className="cardComments">
                                <h5>{el.title}</h5>
                                <p className="m-0">{el.text}</p>
                              </div>
                            </SwiperSlide>

                          )
                        }
                      </>
                    )
                  }
                  
                </Swiper>
                <div className="d-flex justify-content-center">
                  <div className="swiper-pagination"></div>
                </div>
              </div>
              <div className="col-md-6 align-self-center text-center">
                <img src={people} alt="" className='img-fluid'/>
                <div className="labelWhyUs">{spanish?'Estamos aquí para ayudarte.': 'We are here to help you.'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyUs