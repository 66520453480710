import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Layout = ({children, spanish}) => {
  return (
    <>
      <Header spanish={spanish}/>
      {children}
      <Footer spanish={spanish}/>
    </>
  )
}

export default Layout