import { createSlice } from '@reduxjs/toolkit'

const languajeSlice = createSlice({
  name: 'lang',
  initialState: {
    spanish: true,
  },
  reducers: {
    setLanguaje: (state, action) =>{
      state.spanish = action.payload
    }
  },
})

export const { 
  setLanguaje,
 } = languajeSlice.actions
export default languajeSlice.reducer