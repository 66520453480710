import React from 'react';
import { useForm } from 'react-hook-form';

const Form = ({spanish}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  

  const onSubmit = (data) => {
    const { nombreCompleto, destino, fecha, hora, personas } = data;

    const fechaFormateada = new Date(fecha).toLocaleDateString('es-ES', {
      weekday: 'long',  // muestra el nombre completo del día (lunes, martes, etc.)
      day: 'numeric',   // muestra el día del mes (1, 2, 3, etc.)
      month: 'long',    // muestra el nombre completo del mes (enero, febrero, etc.)
      year: 'numeric'   // muestra el año (2023, 2024, etc.)
    });

    const mensaje = `Hola, soy ${nombreCompleto} .\n\n` +
      `Solicito amablemente un servicio de transporte con las siguientes indicaciones :\n\n` +
      `*Destino:* ${destino}\n` +
      `*Fecha:* ${fechaFormateada}\n` +
      `*Hora:* ${hora}\n` +
      `*Número de personas:* ${personas}\n\n` +
      `Muchas gracias `;

    const numeroTelefono = "573105671105";
    const urlWhatsApp = `https://wa.me/${numeroTelefono}?text=${encodeURIComponent(mensaje)}`;

    window.open(urlWhatsApp, '_blank');
    reset();
  };

  return (
    <section className='formSection mb-5 mt-3 mt-md-0'>
      <div className="container">
        <div className="cardForm">
          <h4 className="text-center mb-4">
            {spanish ? '¡Separa tu viaje aquí!': 'Reserve your trip!'}
          </h4>
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="content row">
              <div className='col-md-2 col-12 item'>
                <label>{spanish ? 'Nombre completo': 'Full name'}</label>
                <input
                  type="text"
                  {...register('nombreCompleto', { required: true })}
                  className={errors.nombreCompleto ? 'error' : ''}
                />
              </div>
              <div className='col-md-2 col-6 item my-3 my-md-0'>
                <label>{spanish ? 'Destino': 'Destination'}</label>
                <input
                  type="text"
                  {...register('destino', { required: true })}
                  className={errors.destino ? 'error' : ''}
                />
              </div>
              <div className='col-md-2 col-6 item my-3 my-md-0'>
                <label>{spanish? 'Fecha': 'Date'}</label>
                <input
                  type="date"
                  {...register('fecha', { required: true })}
                  className={errors.fecha ? 'error' : ''}
                />
              </div>
              <div className='col-md-2 col-6 item'>
                <label>{spanish? 'Hora': 'Hour'}</label>
                <input
                  type="time"
                  {...register('hora', { required: true })}
                  className={errors.hora ? 'error' : ''}
                />
              </div>
              <div className='col-md-2 col-6 item'>
                <label>{spanish?'Personas': 'Count people'}</label>
                <div className="inputSelect">
                  <select
                    {...register('personas', { required: true })}
                    className={errors.personas ? 'error' : ''}
                  >
                    <option value="">{spanish?'Seleccionar': 'Select'}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                </div>
              </div>
              <div className='col-md-2 col-12 item align-self-end mt-4 mt-md-0'>
                <button type="submit">{spanish?'COTIZAR': 'RESERVE'}</button>
              </div>
            </form>
          </div>
        </div>
        <h3 className="text-center text-white mb-0">
          {spanish?'Somos la garantía a tu destino': 'We are the guarantee to your destination'}
        </h3>
      </div>
    </section>
  );
};

export default Form;
