import Home from './pages/Home'
import ErrorPage from './pages/error/ErrorPage'
import { Provider } from "react-redux";
import { store } from './redux';
import './sass/main.sass'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const  App = ()=> {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />
    },
  ]);


  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  )
}

export default App
