import React from 'react'
import icon from '../img/vector/icon_aliance.svg'

const CallToAction = ({spanish}) => {
  return (
    <section className='bg-gold py-5 mb-3 mb-md-5'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-11">
            <div className="row">
              <div className="col-md-8 align-self-center">
                <div className="d-flex align-items-center pe-4">
                  <img src={icon} alt="" className='icon-md me-3'/>
                  <h3 className='m-0 text-white'>
                    {
                      spanish ? 
                        'Somos tu aliado confiable para los viajes de negocios'
                        :
                        'We are your reliable partner for business travel.'
                    }
                  </h3>
                </div>
              </div>
              <div className="col-md-4 align-self-center mt-4 mt-md-0">
                <a href='https://api.whatsapp.com/send?phone=573105671105&text=Hola,%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20un%20servicio%20de%20transporte%20desde...' target='_blank' rel='noopener noreferrer' className="btnWhite">{spanish?'Agenda tu servicio': 'Reserve your service'}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction